/* eslint-disable no-underscore-dangle */
import { locationHandler, config, cmpUtils, log, setLogger } from '@cmp/liveramp-cmp-utils';
import AtsHandler from './ats-handler';
import CmpHandler from './cmp-handler';
import LaunchPad, { GLOBAL_NAME } from './launchpad';

const setLoggingFromQueryString = () => {
  const logging = cmpUtils.getUrlParam('logging');
  if (logging === 'true' || logging === 'false') {
    config.logging = logging === 'true';
  }
};

export default function init({ configUpdates }) {
  config.update(configUpdates);

  setLoggingFromQueryString(config);

  setLogger(null, '(LaunchPad)');

  log.debug('Using configuration: ', JSON.stringify(config));
  const startTime = Date.now();

  // Pull queued command from __launchpad stub
  const { commandQueue = [], VERSION } = window[GLOBAL_NAME] || {};

  if (VERSION) {
    log.error('LaunchPad already loaded');
    return;
  }

  // Update launchPadVersion
  const launchPadVersion = parseInt(config.libraryVersion, 10);

  // Replace the __launchpad with our implementation
  const launchPad = new LaunchPad(launchPadVersion);
  if (window.Cypress) {
    window.launchPad = launchPad;
  }

  // Pass all queued commands to LaunchPad
  launchPad.commandQueue = commandQueue;

  // Expose `processCommand` as the LaunchPad implementation
  window[GLOBAL_NAME] = launchPad.processCommand;

  // Notify listeners that the LaunchPad is loaded
  const loadTime = Date.now();
  log.debug(`Successfully loaded version: ${launchPadVersion} in ${loadTime - startTime}ms`);
  launchPad.isLoaded = true;
  launchPad.status = 'loaded';
  launchPad.notify('isLoaded');

  locationHandler
    .getLocation()
    .then(geoLocation => {
      log.debug('Location: ', geoLocation);

      // Execute any previously queued command
      launchPad.processCommandQueue();

      launchPad.isReady = true;
      launchPad.notify('isReady');

      log.debug(`Ready in: ${Date.now() - loadTime}ms`);

      if (!navigator.cookieEnabled) {
        launchPad.notify('disabledCookies');
      }
      // Load cmp
      launchPad.cmpHandler = new CmpHandler(geoLocation);

      launchPad.atsHandler = new AtsHandler(
        geoLocation,
        config.atsRules,
        config.triggers,
        launchPad.cmpHandler,
        launchPad.notify,
      );
    })
    .catch(err => {
      log.error('Failed to load: ', err);
      launchPad.status = 'error';
    });

  return launchPadVersion;
}
