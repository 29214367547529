const CMP_TYPES = {
  GDPR: 'gdpr',
  CCPA: 'ccpa',
  GPP: 'gpp',
};

const CONSENT_TYPES = {
  TCF_V2: '4',
  CCPA: '3',
};

const IDENTIFIER_TYPES = {
  IDENTITY_ENVELOPE: '19',
};

const COUNTRY_CODE = {
  UNITED_STATES: 'US',
  AUSTRALIA: 'AU',
  JAPAN: 'JP',
  SINGAPORE: 'SG',
  INDONESIA: 'ID',
  TAIWAN: 'TW',
  HONG_KONG: 'HK',
};

const ECST_SEGMENTS_URL = 'di.rlcdn.com/api/segment';

export { CMP_TYPES, CONSENT_TYPES, IDENTIFIER_TYPES, ECST_SEGMENTS_URL, COUNTRY_CODE };
