import { config, log } from '@cmp/liveramp-cmp-utils';

export const GLOBAL_NAME = '__launchpad';
export const CALL_NAME = `${GLOBAL_NAME}Call`;
export const LOCATOR_NAME = `${GLOBAL_NAME}Locator`;

export let VERSION = parseInt(config.libraryVersion, 10) || 1;

const RETURN_NAME = `${GLOBAL_NAME}Return`;

const outputCurrentConfiguration = (callback) => {
  const output = {
    configId: config.id,
    atsRules: config.atsRules,
    logging: config.logging,
    preload: config.preload
  }
  if (callback) {
    callback(output);
  } else {
    return output;
  };
};

export default class LaunchPad {
  constructor(version) {
    this.isLoaded = false;
    this.isAtsLoaded = false;
    this.status = 'stub';
    this.eventListeners = {};
    this.processCommand.receiveMessage = this.receiveMessage;
    if (version) {
      VERSION = version;
    }
    this.processCommand.VERSION = VERSION;
    this.commandQueue = [];
    this.processCommand.outputCurrentConfiguration = outputCurrentConfiguration;
  }

  commands = {
    ping: (version, callback) => {
      callback = callback || (() => {});
      if (version && version !== 1) {
        callback(null, false);
        return;
      }

      const result = {
        loaded: this.isLoaded, // true if LaunchPad main script is loaded, false if still running stub
        status: this.status, // stub | loaded | error
        apiVersion: '1.0', // version of the LaunchPad API that is supported, e.g. "1.0"
        libraryVersion: VERSION, // LaunchPads own/internal version that is currently running
        atsLoaded: this.isAtsLoaded, // boolean that shows if ATS library is loaded or not.
      };
      callback(result, true);
    },

    ecst: async (pData, callback) => {
      callback = callback || (() => {});
      this.atsHandler.handleEcstCall(pData, callback);
    },

    /**
     * Add a callback to be fired on a specific event.
     * @param {string} event Name of the event
     */
    addEventListener: (version, callback, event) => {
      callback = callback || (() => {});

      if (version && version !== 1) {
        callback(null, false);
        return;
      }

      if (!event) {
        callback({ error: 'Event not provided' }, false);
        return;
      }

      const eventSet = this.eventListeners[event] || new Set();
      eventSet.add(callback);
      this.eventListeners[event] = eventSet;

      // Trigger load events immediately if they have already occurred
      if (event === 'isLoaded' && this.isLoaded) {
        callback({ event }, true);
      }
    },

    /**
     * Remove a callback for an event.
     * @param {string} event Name of the event to remove callback from
     */
    removeEventListener: (version, callback, event) => {
      callback = callback || (() => {});

      if (version && version !== 1) {
        callback(null, false);
        return;
      }
      let eventListenerRemoved = false;

      // If an event is supplied remove the specific listener
      if (event) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(event)) {
          const eventSet = this.eventListeners[event] || new Set();
          eventSet.clear();
          this.eventListeners[event] = eventSet;
          eventListenerRemoved = true;
        }
      }
      // If no event is supplied clear ALL listeners
      else {
        this.eventListeners = {};
        eventListenerRemoved = true;
      }

      callback(eventListenerRemoved);
    },
  };

  processCommandQueue = () => {
    const queue = [...this.commandQueue];
    if (queue.length) {
      log.info(`Process ${queue.length} queued commands`);
      this.commandQueue = [];
      queue.forEach(data => {
        if (Array.isArray(data)) {
          const [command, version, callback, parameter] = data;
          this.processCommand(command, version, callback, parameter);
        } else {
          const { callId, command, parameter, version, callback, event } = data;

          // If command is queued with an event we will relay its result via postMessage
          if (event) {
            this.processCommand(
              command,
              version,
              (returnValue, success) => {
                const message = {
                  [RETURN_NAME]: {
                    callId,
                    command,
                    returnValue,
                    success,
                  },
                };
                event.source.postMessage(message, event.origin);
              },
              parameter,
            );
          } else {
            this.processCommand(command, version, callback, parameter);
          }
        }
      });
    }
  };

  /**
   * Handle a message event sent via postMessage
   */
  receiveMessage = ({ data, origin, source }) => {
    if (data) {
      const { [CALL_NAME]: launchPad } = data;
      if (launchPad) {
        const { callId, command, parameter, version } = launchPad;
        this.processCommand(
          command,
          version,
          (returnValue, success) => {
            const message = { [RETURN_NAME]: { callId, command, returnValue, success } };
            source.postMessage(message, origin);
          },
          parameter,
        );
      }
    }
  };

  /**
   * Call one of the available commands.
   * @param {string} command Name of the command
   * @param {*} parameter Expected parameter for command
   * @param {*} version Expected launchPad version
   */
  processCommand = (command, version, callback, parameter) => {
    if (typeof this.commands[command] !== 'function') {
      log.error(`Invalid Command "${command}"`);
    }
    // Special case where we have the full launchPad implementation loaded but
    // we still queue these commands until launchPad is ready and CMP is loaded
    else if (command !== 'ecst' && command !== 'ping' && command !== 'addEventListener' && !this.isReady) {
      this.pushToCommandQueue(command, version, callback, parameter, 'launchpad');
    } else if (command === 'ecst' && !this.isAtsLoaded) {
      this.pushToCommandQueue(command, version, callback, parameter, 'ATS');
    } else {
      log.info(`Process command: ${command}, parameter: ${parameter}, version: ${version}`);
      this.commands[command](version, callback, parameter);
    }
  };

  pushToCommandQueue = (command, version, callback, parameter, requirement) => {
    log.info(`Queuing command: ${command} until ${requirement} is ready`);
    this.commandQueue.push({
      command,
      version,
      callback,
      parameter,
    });
    this.notify('commandQueued', { command });
  };

  /**
   * Trigger all event listener callbacks to be called.
   * @param {string} event Name of the event being triggered
   * @param {*} data Data that will be passed to each callback
   */
  notify = (event, data) => {
    log.info(`Notify event: ${event}`);
    const eventSet = this.eventListeners[event] || new Set();
    eventSet.forEach(callback => {
      callback({ event, data }, true);
    });

    // Process any queued commands that were waiting for CMP to be loaded
    if (event === 'atsWrapperLoaded') {
      this.isAtsLoaded = true;
      this.processCommandQueue();
    }
  };
}
