/* eslint no-underscore-dangle: 0 */
import { cmpUtils, locationHandler, config, log } from '@cmp/liveramp-cmp-utils';
import { sendDataToEcst, listenForEcstModuleReady, ecstModule, getRenderedSlotsData } from '@launchpad-workspace/ecst';
import { CMP_TYPES, CONDITION_TYPES, INTEGRATION_TYPES, LOAD_EVENTS } from './enums';
import { readStoredEnvelope } from './storage';

export default class AtsHandler {
  constructor(geoLocation, atsRules, integrations, cmpHandler, notify) {
    this.cmpHandler = cmpHandler;
    this.intervalId = setInterval(this.checkConsent, 100, geoLocation, atsRules, integrations, notify);
    this.geoLocation = geoLocation;
    this.ecst;
    this.iabConsent = {};
    this.moduleNames = [];
    this.storedDirectEnvelopeDeals;
  }

  checkConsent = (geoLocation, atsRules, integrations, notify) => {
    this.checkConsentForAts(geoLocation, atsRules, integrations, notify);
    clearInterval(this.intervalId);
  };

  checkConsentForAts = async (geoLocation, atsRules, integrations, notify) => {
    if (!this.cmpHandler.isUserPrivacySignalEnabled()) {
      window.addEventListener('launchpadRecheckAtsConsentEvent', event => {
        this.iabConsent = Object.assign(this.iabConsent, event.detail);
        if (typeof this.iabConsent.consent === 'boolean') {
          if (this.iabConsent.consent) {
            this.findAndLoadAts(geoLocation, atsRules, integrations, notify);
          } else {
            log.debug(`There's no consent for ATS.`);
          }
        }
        if (
          this.iabConsent.cmpType &&
          (this.iabConsent.cmpType === CMP_TYPES.GDPR || this.iabConsent.cmpType === CMP_TYPES.GPP)
        ) {
          this.cmpHandler.setUserInteractionHandler(
            this.iabConsent,
            this.findAndLoadAts,
            geoLocation,
            atsRules,
            integrations,
            notify,
          );
        }
      });
      this.cmpHandler.iabCheckConsent();
    }
  };

  findAndLoadAts = (geoLocation, atsRules, integrations, notify) => {
    if (window.ats) {
      if (this.iabConsent && this.iabConsent.consent && this.cmpHandler.consentCheckPassed) {
        log.debug('Consent was given. Application is already running!');
      } else {
        for (let i = 0; i < this.moduleNames.length; i++) {
          cmpUtils.dispatchCustomEvent(`${this.moduleNames[i]}LaunchpadCommunicationEvent`, this.iabConsent);
        }
      }
    } else if (atsRules && atsRules.length > 0 && !window.ats) {
      const atsRule = atsRules.find(
        item => item.type === INTEGRATION_TYPES.ATS && this.isAtsAllowedToLoad(item.triggers, geoLocation),
      );
      if (atsRule) {
        this.loadAts(atsRule.id, atsRule.triggers, atsRule.ecst, notify);
      }
    } else if (integrations && integrations.length > 0 && !window.ats) {
      const atsIntegration = integrations.find(
        item =>
          item.integrationType === INTEGRATION_TYPES.ATS &&
          this.isAtsAllowedToLoad(item.integration.conditions, geoLocation),
      );
      if (atsIntegration) {
        this.loadAts(atsIntegration.integration.configId, atsIntegration.integration.conditions, null, notify);
      }
    } else if (!window.ats) {
      log.debug('Config without ATS.');
    }
    if (this.iabConsent) {
      this.cmpHandler.consentCheckPassed = this.iabConsent.consent;
    }
  };

  isAtsAllowedToLoad = (conditions, geoLocation) => {
    const geoTargeting = this.getGeoTargeting(conditions);
    const domains = this.getDomains(conditions);

    if (
      locationHandler.isLocationSupported(geoLocation, geoTargeting) &&
      this.isDomainFulfilled(domains, window.location.hostname)
    ) {
      return true;
    }

    log.debug(`ATS loading rules aren't fulfilled.`);
    return false;
  };

  loadAts = (id, conditions, ecst, notify) => {
    const loadEvent = this.getLoadEvent(conditions);

    switch (loadEvent) {
      case LOAD_EVENTS.PAGE_VIEW:
        this.insertAts(id, ecst, notify);
        break;
      case LOAD_EVENTS.DOM_READY:
        this.loadOnDomReady(id, ecst, notify);
        break;
      case LOAD_EVENTS.WINDOW_LOADED:
        this.loadOnWindowLoaded(id, ecst, notify);
        break;
      default:
        log.debug(`Unsupported load event.`);
        break;
    }
  };

  getGeoTargeting = conditions => {
    const condition = conditions.find(c => c.type === CONDITION_TYPES.GEO_TARGETING);
    if (condition) {
      return condition.geoTargeting;
    }
    return null;
  };

  getDomains = conditions => {
    const domains = [];
    const condition = conditions.find(c => c.type === CONDITION_TYPES.PAGE_VIEW);
    if (condition) {
      condition.rules.forEach(rule => {
        domains.push(rule.value);
      });
    }
    return domains;
  };

  getLoadEvent = conditions => {
    const condition = conditions.find(c => c.type === CONDITION_TYPES.LOAD_EVENT);
    if (condition) {
      return condition.loadEvent;
    }
    return null;
  };

  isDomainFulfilled = (domains, domain) => {
    const urlParam = cmpUtils.getUrlParam('domain');
    if (urlParam) {
      domain = this.extractHostname(urlParam);
    }
    if (domain) {
      domain = this.removeWWW(domain);
      return domains.some(value => {
        if (value) {
          value = this.extractHostname(value);
          value = this.removeWWW(value);
          return domain.indexOf(value) > -1 || value.indexOf(domain) > -1;
        }
        return false;
      });
    }
    return false;
  };

  extractHostname = value => {
    if (value) {
      if (value.indexOf('//') > -1) {
        [, , value] = value.split('/');
      } else {
        [value] = value.split('/');
      }

      [value] = value.split(':');
      [value] = value.split('?');
    }
    return value;
  };

  removeWWW = value => {
    if (value && value.indexOf('www.') === 0) {
      value = value.replace('www.', '');
    }
    return value;
  };

  loadOnDomReady = (id, ecst, notify) => {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      this.insertAts(id, ecst, notify);
    } else {
      window.addEventListener('DOMContentLoaded', () => {
        this.insertAts(id, ecst, notify);
      });
    }
  };

  loadOnWindowLoaded = (id, ecst, notify) => {
    if (document.readyState === 'complete') {
      this.insertAts(id, ecst, notify);
    } else {
      window.addEventListener('load', () => {
        this.insertAts(id, ecst, notify);
      });
    }
  };

  insertAts = (id, ecst, notify) => {
    notify = notify || (() => {});
    const wrapperSrc = `${config.atsConfigUrl}/ats-modules/${id}/ats.js`;
    log.debug('Insert ATS wrapper with source: ', wrapperSrc);
    const wrapperScript = document.createElement('script');
    wrapperScript.setAttribute('src', wrapperSrc);
    wrapperScript.setAttribute('id', 'liveramp-ats-wrapper');
    window.addEventListener('atsConsentGatheringStartedEvent', event => {
      cmpUtils.dispatchCustomEvent(`${event.detail}LaunchpadCommunicationEvent`, this.iabConsent);
      if (event.detail && !this.moduleNames.includes(event.detail)) {
        this.moduleNames.push(event.detail);
      }
    });
    document.head.appendChild(wrapperScript);
    wrapperScript.onload = () => {
      this.ecst = ecst;
      log.debug('ATS wrapper loaded');
      notify('atsWrapperLoaded');
      listenForEcstModuleReady(this.geoLocation, log, ecst);
      if (ecst && (ecst.enableGoogleTagInteraction || ecst.enableAtsDirectMeasurement)) {
        // check if ATS Direct Deals(segments) should be sent to eCST
        if (this.envelopeModuleHasDirectIntegration()) {
          this.setEcstAdsEventListener();
        }
      }
    };
    wrapperScript.onerror = () => {
      log.error('Unable to load ATS wrapper: ', wrapperSrc);
      notify('atsWrapperNotLoaded');
    };
  };

  /**
   * Checks if there is ATS Direct envelope stored in cookie or local storage - returns ATS Direct deals data | null
   * @returns {Object | null} - example: { atsdealid1: '123456', atsdealid2: '234567' }
   */
  getStoredDirectEnvelopeDeals = () => {
    const storedDirectEnvelope = readStoredEnvelope();
    if (storedDirectEnvelope) {
      return this.createAtsDirectDealsData(storedDirectEnvelope);
    }
    return null;
  };

  /**
   * Creates key value pairs out of ATS Direct Envelope, so they are in the right format for the eCST
   * @param {string[]} envelopeDeals  - ['1234556', '23455566']
   * @returns {Object} - example: { atsdeal1: '123456', atsdeal1: '234567' }
   */
  createAtsDirectDealsData = envelopeDeals => {
    const deals = {};
    if (envelopeDeals && envelopeDeals.length > 0) {
      envelopeDeals.forEach((dealId, i) => {
        deals[`atsdealid${i + 1}`] = dealId.toString();
      });
    }
    return deals;
  };

  sendDataToEcstOnDirectEnvelopePresent = googleTagData => {
    window.addEventListener('atsDirectEnvelopePresent', () => {
      // before firing atsDirectEnvelopePresent event, new ATS Direct envelope was stored in localStorage/cookie
      this.storedDirectEnvelopeDeals = this.getStoredDirectEnvelopeDeals();
      const ecstData = Object.assign(googleTagData, this.storedDirectEnvelopeDeals);
      this.handleEcstCall(ecstData, data => console.log(data));
    });
  };

  /**
   * Checks if loaded ATS Envelope Module config has ATS Direct Integration
   * @returns {boolean}
   */
  envelopeModuleHasDirectIntegration = () => {
    const atsConfig = window.ats.outputCurrentConfiguration(currentConfig => currentConfig);
    if (atsConfig && atsConfig.ENVELOPE_MODULE_INFO) {
      return !!atsConfig.ENVELOPE_MODULE_INFO.ENVELOPE_MODULE_CONFIG.useDirect;
    }
    return false;
  };

  setEcstAdsEventListener = () => {
    if (window.googletag && window.googletag.cmd) {
      // Check if gpt Ads are already rendered - and fire ecst for slots that rendered
      if (window.googletag.pubadsReady) {
        const renderedSlotsData = getRenderedSlotsData();

        if (renderedSlotsData.length > 0) {
          renderedSlotsData.forEach(slotData => {
            this.sendGptDataToEcst(slotData);
          });
        }
      }

      // Set event listener for future Ads renders
      window.googletag.cmd.push(() => {
        window.googletag.pubads().addEventListener('slotRenderEnded', event => {
          const { slot } = event;
          const googleTagData = {
            slot: slot.getSlotElementId(),
            advertiserId: event.advertiserId.toString(),
            campaignId: event.campaignId.toString(),
            creativeId: event.creativeId.toString(),
            lineItemId: event.lineItemId.toString(),
          };
          this.sendGptDataToEcst(googleTagData);
        });
      });
    }
  };

  sendGptDataToEcst = googleTagData => {
    if (!this.storedDirectEnvelopeDeals) {
      this.storedDirectEnvelopeDeals = this.getStoredDirectEnvelopeDeals();
    }

    if (this.storedDirectEnvelopeDeals) {
      const ecstData = Object.assign(googleTagData, this.storedDirectEnvelopeDeals);
      this.handleEcstCall(ecstData, data => console.log(data));
    } else {
      this.sendDataToEcstOnDirectEnvelopePresent(googleTagData);
    }
  };

  setEcstParameterData = ecstParametersData => {
    ecstParametersData = ecstParametersData || {};
    ecstParametersData.pid = this.ecst.id;

    const { cmpType, consentString } = this.iabConsent;

    if (cmpType === CMP_TYPES.GPP) {
      const { sectionId } = this.iabConsent;
      ecstParametersData.gpp = consentString;
      ecstParametersData.gpp_sid = sectionId;
    } else if (cmpType === CMP_TYPES.CCPA) {
      ecstParametersData.uspString = consentString;
      ecstParametersData.ct = CMP_TYPES.CCPA;
    } else if (cmpType === CMP_TYPES.GDPR) {
      ecstParametersData.ct = CMP_TYPES.GDPR;
    }

    return ecstParametersData;
  };

  checkConsentForEcst = () =>
    locationHandler.isLocationUs(this.geoLocation.country)
      ? this.iabConsent.consent
      : !this.cmpHandler.isUserPrivacySignalEnabled();

  sendDataToEcstModule = async (pData, ecstParametersData, callback) => {
    pData = pData || null;
    ecstParametersData = ecstParametersData || {};
    this.setEcstParameterData(ecstParametersData);

    const consentForEcst = this.checkConsentForEcst();

    if (!consentForEcst) {
      callback('Not able to send data to eCST. No consent exists.');
    } else {
      const ecstResponse = await sendDataToEcst(ecstParametersData, pData);
      callback(ecstResponse);
    }
  };

  handleEcstCall = (pData, callback) => {
    if (ecstModule.loaded) {
      this.sendDataToEcstModule(pData, null, callback, true);
    } else {
      window.addEventListener('ecstModuleReady', () => {
        this.sendDataToEcstModule(pData, null, callback);
      });
      listenForEcstModuleReady(this.geoLocation, log, this.ecst);
    }
  };
}
