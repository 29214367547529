/* eslint no-underscore-dangle: 0 */
import { cmpUtils, locationHandler, log } from '@cmp/liveramp-cmp-utils';
import { gppConsentCheck, tcfConsentCheck } from '@cmp/iab-consent-check';

export default class CmpHandler {
  constructor(geoLocation) {
    this.geoLocation = geoLocation;
    this.consentCheckPassed = false;
  }

  isUserPrivacySignalEnabled = () => cmpUtils.isDoNotTrackEnabled() || cmpUtils.isGlobalPrivacyControlEnabled();

  setUserInteractionHandler = (iabConsent, findAndLoadAts, ...args) => {
    window.addEventListener(`launchpadUserActionCompleteEvent`, event => {
      iabConsent = Object.assign(iabConsent, event.detail);
      findAndLoadAts(...args);
    });
  };

  iabCheckConsent = () => {
    if (locationHandler.isLocationUs(this.geoLocation.country)) {
      this.checkUsConsent();
    } else if (tcfConsentCheck.gdprApplies(this.geoLocation.country)) {
      this.checkTcfConsent();
    } else {
      cmpUtils.dispatchCustomEvent('launchpadRecheckAtsConsentEvent', { consent: true });
    }
  };

  checkTcfConsent = () => tcfConsentCheck.getTcfConsentObject(true, 'launchpad');

  checkUsConsent = () => {
    window.addEventListener(`launchpadCcpaFallbackEvent`, () => {
      this.onCcpaFallbackHandler();
    });
    gppConsentCheck.getGppConsentObject(this.geoLocation.region, 'launchpad');
  };

  onCcpaFallbackHandler = () => {
    log.debug(`GPP library didn't load in time.`);
    if (window.__uspapi) {
      log.debug('CCPA library is present');
      this.checkCcpaConsent();
    } else {
      log.debug('Library __uspapi is not present.');
      cmpUtils.dispatchCustomEvent('launchpadRecheckAtsConsentEvent', { consent: true });
    }
  };

  checkCcpaConsent = () => {
    const ccpaConsent = { consent: null, consentString: null };

    window.__uspapi('getUSPData', 1, (data, success) => {
      if (data.uspString === null) {
        log.debug('User did not interact with consent manager.');
        Object.assign(ccpaConsent, { consent: true }); // user didn't set consent, continue the flow
      }
      if (success) {
        Object.assign(ccpaConsent, {
          cmpType: 'ccpa',
          consent: this.parseCcpaConsentString(data.uspString),
          consentString: data.uspString,
        });
      } else {
        log.debug('There was a problem getting CCPA data.');
        Object.assign(ccpaConsent, { consent: true });
      }
      cmpUtils.dispatchCustomEvent('launchpadRecheckAtsConsentEvent', ccpaConsent);
    });
    return ccpaConsent;
  };

  parseCcpaConsentString(consentString) {
    if (consentString.length !== 4) {
      log.debug('CCPA consent string is not 4 characters long!');
      return false;
    }
    const consent = consentString.split('')[2];
    if (consent === 'Y') {
      log.debug("User didn't give consent.");
      return false;
    }
    if (consent === 'N') {
      log.debug('User gave consent');
    } else if (consent === '-') {
      log.debug("CCPA doesn't apply to this user.");
    }
    return true;
  }
}
