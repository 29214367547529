const ENVELOPE_TYPE = {
  ATS_DIRECT: '_lr_atsDirect',
};
const CMP_TYPES = {
  GDPR: 'gdpr',
  CCPA: 'ccpa',
  GPP: 'gpp',
};

const INTEGRATION_TYPES = {
  ATS: 'ATS',
};

const LOAD_EVENTS = {
  PAGE_VIEW: 'PAGE_VIEW',
  DOM_READY: 'DOM_READY',
  WINDOW_LOADED: 'WINDOW_LOADED',
};

const CONDITION_TYPES = {
  LOAD_EVENT: 'LOAD_EVENT',
  GEO_TARGETING: 'GEO_TARGETING',
  PAGE_VIEW: 'PAGE_VIEW',
};

export { INTEGRATION_TYPES, LOAD_EVENTS, CONDITION_TYPES, CMP_TYPES, ENVELOPE_TYPE };
