/* eslint-disable */
(function () {
  if (typeof window.__launchpad !== 'function') {
    var queue = [];
    var win = window;
    var doc = win.document;

    var launchPadStart = win.__launchpad ? win.__launchpad.start : function () {};

    function addFrame() {
      /**
       * check for launchPadLocator
       */
      var launchPadLocator = !!win.frames['__launchpadLocator'];

      if (!launchPadLocator) {
        /**
         * There can be only one
         */
        if (doc.body) {
          /**
           * check for body tag – otherwise we'll be
           * be having a hard time appending a child
           * to it if it doesn't exist
           */
          var iframe = doc.createElement('iframe');

          iframe.style.cssText = 'display:none';
          iframe.name = '__launchpadLocator';
          doc.body.appendChild(iframe);
        } else {
          /**
           * Wait for the body tag to exist.
           *
           * Since this API "stub" is located in the <head>,
           * setTimeout allows us to inject the iframe more
           * quickly than relying on DOMContentLoaded or
           * other events.
           */
          setTimeout(addFrame, 5);
        }
      }

      /**
       * if there was no launchPadLocator then we have succeeded
       */
      return !launchPadLocator;
    }

    function __launchpad(command, version, callback, parameter) {
      var args = [command, version, callback, parameter];
      if (!args.length) {
        /**
         * shortcut to get the queue when the full launchPad
         * implementation loads; it can call __launchpad()
         * with no arguments to get the queued arguments
         */
        return queue;
      } else if (args[0] === 'ping') {
        /**
         * Only supported method;
         * give PingReturn object as response
         */
        if (typeof args[2] === 'function') {
          args[2](
            {
              loaded: false,
              apiVersion: '1.0',
            },
            true,
          );
        }
      } else {
        /**
         * some other method
         * just queue it for the full launchPad implementation to deal with
         */
        queue.push(args);
      }
    }

    function postMessageEventHandler(event) {
      var msgIsString = typeof event.data === 'string';
      var json = {};

      try {
        /**
         * Try to parse the data from the event. This is important
         * to have in a try/catch because often messages may come
         * through that are not JSON
         */
        json = msgIsString ? JSON.parse(event.data) : event.data;
      } catch (ignore) {}

      var payload = json.__launchpadCall;
      if (payload) {
        /**
         * the message we care about will have a payload
         */
        win.__launchpad(
          payload.command,
          payload.version,
          function (retValue, success) {
            if (event.source) {
              var returnMsg = {
                __launchpadReturn: {
                  returnValue: retValue,
                  success: success,
                  callId: payload.callId,
                  command: payload.command,
                },
              };

              if (msgIsString) {
                /**
                 * If we were given a string, we'll respond in kind
                 */
                returnMsg = JSON.stringify(returnMsg);
              }

              event.source.postMessage(returnMsg, '*');
            }
          },
          payload.parameter,
        );
      }
    }

    if (!win.__launchpad && addFrame()) {
      win.__launchpad = __launchpad;
      win.__launchpad.commandQueue = queue;
      win.__launchpad.start = launchPadStart;
      win.addEventListener('message', postMessageEventHandler, false);
    }
  }
})();
