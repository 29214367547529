import { config, log } from '@cmp/liveramp-cmp-utils';
import init from './init';
import { GLOBAL_NAME } from './launchpad';
import './stub';

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function fetchConfigUpdates(path) {
  fetch(path, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(data => {
      init({ configUpdates: data.config });
    })
    .catch(err => {
      log.error('Failed to load config: ', err);
      init();
    });
}

function launchPadStart(configUpdates) {
  if (/MSIE/.test(navigator.userAgent)) {
    log.info('Your browser is not supported by the LaunchPad. Please update to a more recent one.');
    return;
  }
  const defaultConfig = process.env.CONFIG || config.generate();
  config.update(defaultConfig, true);

  if (!configUpdates || configUpdates === Object(configUpdates)) {
    init({ configUpdates });
  } else {
    fetchConfigUpdates(configUpdates);
  }
}

const launchPad = window[GLOBAL_NAME] || {};
if (isObjectEmpty(launchPad)) {
  window[GLOBAL_NAME] = launchPad;
}
launchPad.start = launchPadStart;
