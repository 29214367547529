import { locationHandler, cmpUtils } from '@cmp/liveramp-cmp-utils';
import { CMP_TYPES, CONSENT_TYPES, IDENTIFIER_TYPES, ECST_SEGMENTS_URL, COUNTRY_CODE } from './enums';

export const ecstModule = { loaded: false };

const retrieveEnvelopeData = async () => {
  const envelopeData = {};
  const envelopeValue = await window.ats.retrieveEnvelope();

  if (envelopeValue) {
    envelopeData.it = IDENTIFIER_TYPES.IDENTITY_ENVELOPE;

    const retrievedEnvelopeValue = decodeURIComponent(envelopeValue);
    const { envelope } = JSON.parse(retrievedEnvelopeValue);
    envelopeData.iv = envelope;
  }

  return envelopeData;
};

const handleEcstParameterData = ecstParameterData => {
  ecstParameterData = ecstParameterData || {};

  if (ecstParameterData) {
    if (ecstParameterData.ct === CMP_TYPES.CCPA) {
      ecstParameterData.ct = CONSENT_TYPES.CCPA;
    }
    if (ecstParameterData.ct === CMP_TYPES.GDPR) {
      ecstParameterData.ct = CONSENT_TYPES.TCF_V2;
    }
  }
  if (!ecstParameterData.cv || !ecstParameterData.ct) {
    delete ecstParameterData.ct;
    delete ecstParameterData.cv;
  }
  delete ecstParameterData.uspString;

  return ecstParameterData;
};

const encodeParams = params => {
  if (params) {
    return Object.keys(params)
      .filter(key => params[key])
      .map(key => [key, params[key]].map(encodeURIComponent).join('='))
      .join('&');
  }
};

const encodeEcstUrl = (ecstParameterData, envelopeData) =>
  `https://${ECST_SEGMENTS_URL}?${encodeParams({ ...ecstParameterData, ...envelopeData })}`;

const fetchEcstEndpoint = async (url, pData) => {
  const options = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (pData) {
    options.body = JSON.stringify({ segments: pData });
  }

  try {
    const response = await fetch(url, options);
    if (response.status === 200 && response.ok) {
      return 'Data Successfully sent to eCST.';
    }
  } catch (error) {
    console.error(error);
    return error.message;
  }
};

export const sendDataToEcst = async (ecstParameterData, pData) => {
  if (window.ats) {
    const envelopeData = await retrieveEnvelopeData();
    const consentParams = handleEcstParameterData(ecstParameterData);
    const ecstUrl = encodeEcstUrl(consentParams, envelopeData);

    return fetchEcstEndpoint(ecstUrl, pData);
  }
};

export const isEcstSupported = (isLocationSupported, ecst) => isLocationSupported && ecst && ecst.enabled;

export const sendEcstReadySignal = log => {
  if (!ecstModule.loaded) {
    ecstModule.loaded = true;
    log.info('ECST module is Ready.');
    cmpUtils.dispatchCustomEvent('ecstModuleReady');
  }
};

export const isLocationSupportedByEcst = country =>
  [
    COUNTRY_CODE.UNITED_STATES,
    COUNTRY_CODE.AUSTRALIA,
    COUNTRY_CODE.HONG_KONG,
    COUNTRY_CODE.INDONESIA,
    COUNTRY_CODE.JAPAN,
    COUNTRY_CODE.SINGAPORE,
    COUNTRY_CODE.TAIWAN,
  ].includes(country);

export const listenForEcstModuleReady = (geoLocation, log, ecst) => {
  if (isEcstSupported(isLocationSupportedByEcst(geoLocation.country), ecst)) {
    if (locationHandler.isLocationUs(geoLocation.country)) {
      let librariesChecked = 0;
      const interval = setInterval(() => {
        librariesChecked += 1;
        // eslint-disable-next-line no-underscore-dangle
        if (window.__uspapi || window.__gpp || librariesChecked === 35) {
          sendEcstReadySignal(log);
          clearInterval(interval);
        }
      }, 200);
    } else {
      sendEcstReadySignal(log);
    }
  }
};
